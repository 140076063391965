import { Spin, Button, Result, Row, Col } from "antd";
import { useMutation, useQuery } from "react-query";
import { IConfirmUserSignup, Authentication } from "./App";

export const ConfirmSignup = ({
  UserPoolId,
  Username,
  confirmationCode,
  poolClientId,
  returnURL,
}: IConfirmUserSignup) => {
  Authentication.configure({
    userPoolId: UserPoolId,
    userPoolWebClientId: poolClientId,
  });

  const { isLoading, error, isSuccess }: any = useQuery(
    "confirmSignup",
    () => Authentication.confirmSignUp(Username, confirmationCode),
    { refetchOnWindowFocus: false }
  );

  const {
    isLoading: isResendingConfirmation,
    isSuccess: successResendingConfirmation,
    mutate: resendConfirmationCode,
  }: any = useMutation((username: string) => {
    const clientMetadata = {
      returnURL: returnURL,
    };
    return Authentication.resendSignUp(username, clientMetadata);
  });

  const customSubTitle = (subTitle: string, md?: number) => (
    <Row justify={"center"}>
      <Col lg={md ?? 24} sm={24} style={{ textAlign: "center" }}>
        <h3>{subTitle}</h3>
      </Col>
    </Row>
  );

  if (isSuccess) {
    return (
      <Result
        status="success"
        title="Congratulations! Your email address has been verified 🎉 "
        subTitle={customSubTitle("You can now sign in and start enjoying all the benefits of our platform!", 11)}
        extra={[
          <Row justify={"center"}>
            <Row justify="center">
              <Button
                className="nav-button"
                type="primary"
                shape="round"
                key="login"
                onClick={() => window.location.replace(returnURL)}
              >
                Login
              </Button>
            </Row>
          </Row>,
        ]}
      />
    );
  }
  if (isLoading) {
    return (
      <Spin
        tip="Confirming ..."
        size="default"
        className="verification-spinner"
        style={{ color: "#004f9e" }}
      ></Spin>
    );
  }

  if (error && !successResendingConfirmation && !isResendingConfirmation) {
    if (
      error.code === "ExpiredCodeException" ||
      error.code === "CodeMismatchException"
    ) {
      return (
        <Result
          status="error"
          title="Confirmation Failed"
          subTitle={customSubTitle(error.message)}
          extra={[
            <Button
              className="nav-button"
              type="primary"
              shape="round"
              key="resend-confirmation-email"
              onClick={() => resendConfirmationCode(Username)}
            >
              Request New Confirmation Code
            </Button>,
          ]}
        />
      );
    }
    if (
      error.code === "LimitExceededException" ||
      error.code === "NotAuthorizedException"
    ) {
      return (
        <Result
          status="error"
          title="Confirmation Failed"
          subTitle={customSubTitle(error.message)}
        />
      );
    }
  }

  if (isResendingConfirmation && !error) {
    return (
      <Spin
        tip="Resending Confirmation Email ..."
        size="default"
        className="verification-spinner"
        style={{ color: "#004f9e" }}
      />
    );
  }

  if (successResendingConfirmation && !error) {
    return (
      <Result
        status="success"
        title="🎉 Confirmation Email Sent!"
        subTitle={customSubTitle("Check you mail inbox to proceed.")}
      />
    );
  }
  return (
    <Result
      status="error"
      title="Confirmation Failed!"
      subTitle={customSubTitle("Unknow error,Please contact customer support.")}
    />
  );
};
