//@ts-check
import { Spin, Button, Result, Typography, Form, Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import Amplify, { Auth } from "aws-amplify";
import { useMutation, useQuery } from "react-query";
import "./App.css";
import { FormInstance } from "antd/lib/form";
import axios from "axios";
import { useEffect } from "react";
import { ConfirmSignup } from "./ConfirmSignup";
import LogRocket from "logrocket";
import { LegacyResetPassword } from "./LegacyResetPassword";
const { Paragraph, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

Amplify.configure({
  Auth: {
    userPoolWebClientId: "",
  },
});

export const Authentication = Auth;
const url = new URL(window.location.href);

export interface IConfirmUserSignup {
  UserPoolId: string;
  Username: string;
  confirmationCode: string | any;
  poolClientId: string;
  returnURL: string;
}

const setLogRocketUser = ({
  name,
  email,
  poolId,
}: {
  name: string;
  email: string;
  poolId: string;
}) =>
  LogRocket.identify("userID", {
    name,
    email,
    poolId,
  });

export default function App() {
  const [form] = Form.useForm();
  const poolId = url.searchParams.get("poolId");
  const userName = url.searchParams.get("user_name");
  const confirmationType = url.searchParams.get("type");
  const returnURL: string = url.searchParams.get("returnURL") || "#";
  const userEmail: string = url.searchParams.get("userEmail") || "";

  if (window.location.pathname === "/activate/") {
    return <HandleLegacyAuthentication />;
  }
  if (window.location.pathname === "/reset-password/") {
    return <LegacyResetPassword />;
  }

  if (!poolId || !userName) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong. here"
        extra={
          <a type="primary" href="mailto:tech@clickpesa.com">
            Contact Support
          </a>
        }
      />
    );
  }
  setLogRocketUser({ name: userName, email: userEmail, poolId });
  const resetPasswordCode = url.searchParams.get("code");
  const poolClientId = url.searchParams.get("clientId");
  const confirmationCode = url.searchParams.get("confirmation_code");
  return confirmationType === "confirmSignup" ? (
    <ConfirmSignup
      UserPoolId={poolId}
      Username={userName}
      confirmationCode={confirmationCode?.split(">Click Here</a>")[0]}
      poolClientId={poolClientId || ""}
      returnURL={returnURL}
    />
  ) : (
    <ResetPassword
      form={form}
      userName={userName}
      confirmationCode={resetPasswordCode}
      UserPoolId={poolId}
      poolClientId={poolClientId || ""}
      returnURL={returnURL}
    />
  );
}

const HandleLegacyAuthentication = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const { isSuccess, isLoading, error }: any = useQuery(
    "legacyAuthentication",
    () => axios.post(`${process.env.REACT_APP_API_URL}/verify-email/${token}`),
    { refetchOnWindowFocus: false }
  );
  if (!token) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <a type="primary" href="mailto:tech@clickpesa.com">
            Contact Support
          </a>
        }
      />
    );
  }
  if (isSuccess) {
    return (
      <Result
        status="success"
        title="🎉 Congratulations! Your email address has been verified."
        subTitle="You are one step away from experiencing a toolbox of simplified business payment solutions in Africa."
      />
    );
  }
  if (isLoading)
    return (
      <Spin
        tip="Verifying ..."
        size="default"
        className="verification-spinner"
        style={{ color: "#004f9e" }}
      ></Spin>
    );

  if (error)
    return (
      <Result
        status="error"
        title="Verification Failed"
        subTitle={error.message}
        extra={[
          <a
            type="primary"
            href="mailto:tech@clickpesa.com"
            style={{ textAlign: "center", display: "block", marginTop: 10 }}
            key="support"
          >
            Contact Support
          </a>,
        ]}
      />
    );
  return null;
};

const ResetPassword = ({
  form,
  confirmationCode,
  userName,
  UserPoolId,
  poolClientId,
  returnURL,
}: {
  form: FormInstance;
  confirmationCode: string | any;
  userName: string;
  UserPoolId: string;
  poolClientId: string;
  returnURL: string;
}) => {
  Authentication.configure({
    userPoolId: UserPoolId,
    userPoolWebClientId: poolClientId,
  });
  const { isLoading, error, isSuccess, mutate }: any = useMutation(
    (newPassword: string) =>
      Authentication.forgotPasswordSubmit(
        userName,
        confirmationCode,
        newPassword
      )
  );
  const initiateResetPassword = (values: any) => {
    mutate(values.password);
  };

  if (isSuccess) {
    return (
      <Result
        status="success"
        title="🎉 Congratulations! Your password has been updated."
        extra={[
          <Button
            type="primary"
            key="login"
            onClick={() => window.location.replace(returnURL)}
          >
            Proceed to Login
          </Button>,
        ]}
      />
    );
  }
  if (isLoading)
    return (
      <Spin
        tip="Updating ..."
        size="default"
        className="verification-spinner"
        style={{ color: "#004f9e" }}
      ></Spin>
    );

  if (error)
    return (
      <Result
        status="error"
        title="Password Update Failed"
        subTitle={error.message}
        extra={[
          <a
            type="primary"
            href="mailto:tech@clickpesa.com"
            style={{ textAlign: "center", display: "block", marginTop: 10 }}
            key="support"
          >
            Contact Support
          </a>,
        ]}
      >
        {error.response?.data.message && (
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Submission has the following error:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
              {error.response.data.message}
            </Paragraph>
          </div>
        )}
      </Result>
    );

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="reset-password"
      onFinish={initiateResetPassword}
      scrollToFirstError
      className="reset-password-form"
    >
      <h1>Update Password</h1>
      <Form.Item
        name="password"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.length < 8) {
                return Promise.reject(
                  new Error("Password should be at least 8 characters!")
                );
              }
              if (!/\d/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a number!")
                );
              }
              // eslint-disable-next-line no-useless-escape
              if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a special character!")
                );
              }
              if (!/[a-z]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a lowercase character!")
                );
              }
              if (!/[A-Z]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a uppercase character!")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Update Password
        </Button>
      </Form.Item>
    </Form>
  );
};
