import { useMutation } from "react-query";
import axios from "axios";
import { Spin, Button, Result, Form, Input, Typography } from "antd";
import jwt_decode from "jwt-decode";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const LegacyResetPassword = () => {
  const [form] = Form.useForm();
  const url: any = new URL(window.location.href);
  const decodedToken: any = jwt_decode(url.searchParams.get("token"));
  const { isLoading, error, isSuccess, mutate }: any = useMutation(
    ({ password }: any) => {
      const options: any = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/reset-password`,
        headers: { "Content-Type": "application/json" },
        data: {
          email: decodedToken.email_address,
          token: url.searchParams.get("token"),
          new_password: password,
        },
      };
      return axios
        .request(options)
        .then(function (response) {})
        .catch(function (error) {
          console.error(error);
        });
    }
  );

  const initiateResetPassword = (values: any) => {
    mutate(values);
  };

  if (isSuccess) {
    return (
      <Result
        status="success"
        title="🎉 Congratulations! Your password has been updated."
      />
    );
  }
  if (isLoading)
    return (
      <Spin
        tip="Updating ..."
        size="large"
        className="verification-spinner"
      ></Spin>
    );

  if (error)
    return (
      <Result
        status="error"
        title="Password Update Failed"
        subTitle={error.message}
        extra={[
          <a
            type="primary"
            href="mailto:tech@clickpesa.com"
            style={{ textAlign: "center", display: "block", marginTop: 10 }}
            key="support"
          >
            Contact Support
          </a>,
        ]}
      >
        {error.response?.data.message && (
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Submission has the following error:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
              {error.response.data.message}
            </Paragraph>
          </div>
        )}
      </Result>
    );

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="reset-password"
      onFinish={initiateResetPassword}
      scrollToFirstError
      className="reset-password-form"
    >
      <h1>Update Password</h1>
      <Form.Item
        name="password"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.length < 8) {
                return Promise.reject(
                  new Error("Password should be at least 8 characters!")
                );
              }
              if (!/\d/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a number!")
                );
              }
              // eslint-disable-next-line no-useless-escape
              if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a special character!")
                );
              }
              if (!/[a-z]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a lowercase character!")
                );
              }
              if (!/[A-Z]/.test(value)) {
                return Promise.reject(
                  new Error("Password should contain a uppercase character!")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Update Password
        </Button>
      </Form.Item>
    </Form>
  );
};
